import React from "react";
import logo from "../assets/images/terraform-yoga.webp";
import "./TerraformLogo.css";

const TerraformLogo = () => {
  return (
    <div className="logo-wrapper">
      <img className="logo" src={logo} />
      <p className="logo-text">TERRAFORM YOGA</p>
    </div>
  );
};

export default TerraformLogo;
