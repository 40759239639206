import React from "react";
import "./GetInTouchButton.css";

const GetInTouchButton = () => {
  return (
    <a href="#footer">
      <button className="getintouch">Get In Touch</button>
    </a>
  );
};

export default GetInTouchButton;
