import React, { Fragment } from "react";
import "./socialMediaLogo.css";
import facebookLogo from "../assets/images/facebook-logo.webp";
import instagramLogo from "../assets/images/instagram-logo.webp";

interface SocialMediaLogoProps {
  socialMediaName: string;
}

const SocialMediaLogo: React.SFC<SocialMediaLogoProps> = props => {
  let linkUrl = "";
  let logoSrc = "";
  switch (props.socialMediaName) {
    case "INSTAGRAM":
      linkUrl = "http://www.instagram.com/greenbeansflow/";
      logoSrc = instagramLogo;
      break;
    case "FACEBOOK":
      linkUrl = "https://www.facebook.com/greenbeansflow";
      logoSrc = facebookLogo;
      break;
  }

  return (
    <Fragment>
      <a href={linkUrl} className="social-link">
        <img src={logoSrc} />
      </a>
    </Fragment>
  );
};

export default SocialMediaLogo;
