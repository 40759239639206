import React from "react";
import "./Footer.css";
import SocialMediaLogo from "../SocialMediaLogo/SocialMediaLogo";

const Footer = () => {
  return (
    <div id="footer" className="footer">
      <span>&copy; 2020 TerraformYoga | </span>
      <a href="mailto: terraformyoga@gmail.com">Email </a>|
      <span className="social-media-logos">
        <SocialMediaLogo socialMediaName="INSTAGRAM" />
        <SocialMediaLogo socialMediaName="FACEBOOK" />
      </span>
    </div>
  );
};

export default Footer;
