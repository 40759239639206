import React, { Fragment } from "react";
import Jumbotron from "../Jumbotron/Jumbotron";
import "./Layout.css";
import InstagramEmbedded from "../InstagramEmbedded/InstagramEmbedded";
import TerraformLogo from "../TerraformLogo/TerraformLogo";
import SocialMediaLogo from "../SocialMediaLogo/SocialMediaLogo";
import text from "../Constants/Text";
import smile from "../assets/images/smile.jpg";
import Footer from "../Footer/Footer";
import GetInTouchButton from "../GetInTouchButton/GetInTouchButton";

const Layout = () => {
  return (
    <div className="layout-wrapper">
      <div className="navbar-wrapper">
        <div>
          <TerraformLogo />
        </div>
        <div className="navbar-gap" />
        <div>
          <SocialMediaLogo socialMediaName="INSTAGRAM" />
        </div>
        <div>
          <SocialMediaLogo socialMediaName="FACEBOOK" />
        </div>
        <div>
          <GetInTouchButton />
        </div>
      </div>
      <div className="jumbotron-wrapper">
        <div className="jumbotron">{/* <Jumbotron /> */}</div>
        {/* <div className="instagram-embedded">
          <InstagramEmbedded />
        </div> */}
      </div>
      <div className="aboutme-wrapper">
        <span className="aboutme-text">
          <h1 className="terraforming-title">WHAT IS TERRAFORM YOGA</h1>
          <div className="aboutme-text-section1">{text.terraforming1}</div>
          <div className="aboutme-text-section2">{text.terraforming2}</div>
          <div className="aboutme-text-section3">{text.terraforming3}</div>
          <div className="aboutme-text-section3">{text.terraforming4}</div>
        </span>
        <div className="aboutme-image">
          <img className="smile" src={smile} />
        </div>
      </div>
      {/* <div className="whatis-terraform-wrapper">
        <div>Image Title</div>
        <div>Image Centered</div>
        <div>Description</div>
        <div>Get in Touch Button</div>
      </div> */}
      <Footer />
    </div>
  );
};

export default Layout;
