const text = {
  terraforming1: `Terraforming a planet is the act of transforming it into something that can sustain human life. 
    It is what happens when you take something that was previously barren, harsh, yet full of potential, and make adjustments so that it becomes a place where people can live and thrive.`,
  terraforming2: `Let me ask you: when you are by yourself and truly with yourself...without the distraction of Netflix or Instagram or your mind-numbing activity of choice...do you enjoy the company you keep?
    For a long time, for me, that answer was a resounding no.  I would constantly overthink, overanalyze, and criticize myself on a second-by-second basis. `,
  terraforming3: `I would look in the mirror and hate what I saw, I would push myself through painful workouts and starve my way through fad diets, and 
    I would come out on the other side feeling emptier than before.  I was always attempting to shove myself into a mold that wasn’t made for me, 
    and constantly searching for the thing I was missing to feel whole.`,
  terraforming4: `It was through years of practicing yoga and studying different styles that I started to realize: I didn’t need to find something new, 
    I just needed to unlock the potential that was already inside of me. 
    I had been mistreating my body for years and neglecting my mental health entirely, and the journey to finding balance required a lot of undoing and relearning.`
};

export default text;
